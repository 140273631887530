import React from 'react';
import { graphql } from 'gatsby';
import { mergePrismicPreviewData } from 'gatsby-source-prismic';
import { Layout, Slice } from 'components';

const IS_BROWSER = typeof window !== 'undefined';

export const Page = ({ location, data: staticData }) => {
  const previewData = IS_BROWSER && window.__PRISMIC_PREVIEW_DATA__;
  const data = mergePrismicPreviewData({ staticData, previewData });
  const { prismicPage } = data;
  const { data: pageData } = prismicPage;
  const {
    body: sliceData,
    meta_title: metaTitle,
    meta_description: metaDesc,
    open_graph_image: ogImage,
    schema,
    indexed,
  } = pageData;
  const seo = {
    title: metaTitle?.text,
    desc: metaDesc?.text,
    banner: ogImage?.url,
    schema: schema?.text,
    indexed,
  };

  const isHero = sliceData?.find(({ slice_type: sliceType }) => sliceType === 'hero_video');
  return (
    <Layout location={location} seo={seo} floating={isHero}>
      {sliceData.map((slice) => (
        <Slice key={slice.id} data={slice} />
      ))}
    </Layout>
  );
};

export default Page;

export const pageQuery = graphql`
  query PageBySlug($uid: String!) {
    prismicPage(uid: { eq: $uid }) {
      id
      uid
      prismicId
      data {
        indexed
        meta_title {
          text
        }
        meta_description {
          text
        }
        open_graph_image {
          url
        }
        schema {
          text
        }
        body {
          ...PrismicPageBodyTextFragment
          ...PrismicPageBodyHeadingFragment
          ...HeroVideoPageFragment
          ...PrismicPageBodyImagesFragment
          ...PrismicPageBodyStatisticsFragment
          ...PrismicPageBodyTestimonialFragment
          ...PrismicPageBodyBannerFragment
          ...PrismicPageBodyQuoteFragment
          ...PrismicPageBodyQuickLinksFragment
          ...PrismicPageBodyFeaturedArticlesFragment
          ...PrismicPageBodyFeaturedProjectsFragment
          ...PrismicPageBodyFeaturedServicesFragment
          ...PrismicPageBodyFeaturedContentFragment
          ...PrismicPageBodyListCollectionFragment
          ...PrismicPageBodyListArticlesFragment
          ...PrismicPageBodyListTeamFragment
          ...PrismicPageBodyFormFragment
          ...CalendlyEmbedFragment
          ...GoogleMapsEmbedFragment
          ...TypeformEmbedPageFragment
          ...PrismicPageBodyVideoFragment
          ...PrismicPageBodyEbookFragment
        }
      }
    }
  }
`;
